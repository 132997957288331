@import 'styles/_colors.scss';

.policy {
    background-color: $color-background-light;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .privacy-policy-text {
        max-width: 69rem;

        h1, h2 {
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        p {
            margin-bottom: 1rem;
            line-height: 1.6;
        }

        ul {
            display: block;
            list-style-type: disc;
            margin: 1em 0 1em 0;
            padding-left: 3rem;
            line-height: 1.6;

            li {
                display: list-item;
            }
        }
    }
}
