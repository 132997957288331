@import 'styles/_colors.scss';
@import 'styles/_mixins.scss';
@import 'styles/_dimens.scss';

$background-color: $color-background-white;

.logo-container {
    background-color: $background-color;
    border-radius: $default-border-radius;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.2rem;

    @include hover-darken-background($background-color);

    .logo-icon {
        width: 2.5rem;
        height: 2.5rem;
        fill: $color-text-primary;
    }

    .logo-text {
        line-height: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        vertical-align: text-bottom;
        white-space: nowrap;
    }
}
