@import 'styles/_colors.scss';
@import 'styles/_dimens.scss';
@import 'styles/_mixins.scss';

$background-color: $color-background-light;

.landing {
    align-items: center;
    background-color: $background-color;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .landing-logo {
        margin-top: 16vh;
        margin-bottom: 8vh;
        background-color: $background-color;

        @include hover-darken-background($background-color);
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        width: 20rem;
        height: 8rem;
        justify-content: space-evenly;

        .link-button {
            width: 100%;
        }
    }
}
