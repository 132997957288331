@import "styles/_dimens.scss";
@import "styles/_mixins.scss";

@mixin button() {
    align-items: center;
    border: none;
    border-radius: $default-border-radius;
    color: $color-light;
    display: flex;
    justify-content: center;
    height: $default-button-height;
    padding: 0 2rem;
}

@mixin colored-in-button($background-color) {
    @include button();
    @include hover-darken-background($background-color);
}

@mixin border-button($border-color, $hovered-text-color) {
    @include button();
    @include hover-background(null, $border-color);
    @include hover-text($border-color, $hovered-text-color);

    border: 2px solid $border-color;
}
